import customizeColors from '@upstream/cobot_assets/src/customize-colors';

export default function() {
  const meta = document.querySelector('meta[name=x-custom-colors]');
  let colors = {};
  if(meta) {
    colors = JSON.parse(meta.attributes.value.value);
  }
  customizeColors(
    {
      colors: {
        text: colors['text_color'],
        'primary-70': colors['primary_color'],
        'highlight-70': colors['highlight_color'],
        background: colors['background_color'],
        'admin-brand': colors['admin_brand_color']
      }
    }
  );
}